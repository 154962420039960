
import Vue from 'vue'
import Component from 'vue-class-component'
import RouteNames from '@/ui/router/route-names'

@Component
export default class DemoAction extends Vue {
  goToPlans() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }
}
