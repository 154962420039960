
import Vue from 'vue'
import Component from 'vue-class-component'

import DemoAction from '../common/DemoAction.vue'

@Component({
  components: {
    DemoAction,
  },
})
export default class PoolScreenDemo extends Vue {}
